import * as React from "react";
import axios from "axios";
import { graphql } from "gatsby";
import { PageTitle } from "@fitplan/lib/components/Header/PageTitle";
import { PageSubTitle } from "@fitplan/lib/components/Header/PageSubTitle";
import { Input } from "@fitplan/lib/components/Input";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { validateEmail } from "@fitplan/lib/components/UserAuth/common";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import styled from "@emotion/styled";

import localize from "../hoc/Localize";
import PageWrapper from "../components/pageWrapper/PageWrapper";
import { urls } from "../config";
import { reportException } from "../utils/sentry";

interface Props {
    data: {
        sanityForgotPassword: {
            checkSpam: string;
            passwordLink: string;
            sentTo: string;
            successTitle: string;
            placeholder: string;
            title: string;
            error: string;
            submit: string;
        };
    };

}

const ForgotPassword: React.FunctionComponent<Props> = (props) => {
    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [submitted, setSubmitted] = React.useState(false);
    const [pending, setPending] = React.useState(false);
    const strings = props.data.sanityForgotPassword;
    return (
        <PageWrapper
            {...props}
        >
            <Container onSubmit={async (event) => {
                event.preventDefault();
                setPending(true);
                setError("");
                try {
                    const response = await axios({
                        method: "POST",
                        url: `${urls.forgotPassword}`,
                        data: { email },
                    });
                    const { result, error } = response.data;
                    if (result) {
                        setSubmitted(true);
                    }
                } catch (err) {
                    if (err.response && err.response.data) {
                        setError(strings.error);
                    } else {
                        reportException(err);
                    }
                }
                setPending(false);
            }}>
                <PageTitle>{strings.title}</PageTitle>
                {submitted && <SubTitle>{strings.successTitle}</SubTitle>}
                {!submitted && (
                    <>
                        <Email type="email" value={email} placeholder="Account Email Address" onChange={event => {
                            setEmail(event.target.value);
                        }} />
                        {error && <Error>{error}</Error>}
                        <Submit disabled={!validateEmail(email) || pending} type="submit">{strings.submit}</Submit>
                    </>
                )}    
            </Container>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
}

const Container = styled.form`
    display: flex;
    flex-direction: column;

    justify-content: center;
    justify-items: center;
    width: 100%;

    padding: 16px;
    box-sizing: border-box;
`;

const SubTitle = styled(PageSubTitle)`
    margin-top: 24px;
`;

const Email = styled(Input)`
    flex: 0 0 auto;
    width: 100%;
    max-width: 400px;
    margin: auto;

    border-radius: 0;
`;

const Submit = styled(BrandButton)`
    flex: 0 0 auto;

    width: 200px;
    line-height: 24px;
    margin: auto;
    margin-top: 16px;
`;

const Error = styled.p`
    color: #f10364;
    margin-top: 8px;

    text-align: center;
`;

export const query = graphql`
    query forgotPasswordQuery {
        sanityForgotPassword {
            title {
                _type
                en
                es
            }
            submit {
                _type
                en
                es
            }
            error {
                _type
                en
                es
            }
            placeholder {
                _type
                en
                es
            }
            successTitle {
                _type
                en
                es
            }
            sentTo {
                _type
                en
                es
            }
            passwordLink {
                _type
                en
                es
            }
            checkSpam {
                _type
                en
                es
            }
        }
    }
`;

export default localize(ForgotPassword);
